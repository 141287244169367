<template>
  <div class="fluid" id="shifts">
    <validation-observer ref="simpleRules">
      <b-card style="">
        <p class="head-text">Shift & break Configaration</p>
        <div class="row">
          <div v-if="this.logedInUser.roles[0].name !== 'customer'" class="col-md-6">
            <label for="" class="label-font">Company</label>
            <b-form-group class="rounded">
              <v-select
                v-model="selectedCompany"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @input="getCustomers()"
                label="name"
                :options="companies"
                placeholder="Select Company to filter Clients"
              />
            </b-form-group>
          </div>
          <div v-if="this.logedInUser.roles[0].name !== 'customer'" class="col-md-6">
            <label for="" class="label-font"
              >Client <span class="text-danger">*</span></label
            >
            <b-form-group class="rounded">
              <v-select
                v-model="selectedCustomer"
                @input="getClientShifts"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="clients"
                placeholder="Select Client for Shift Configaration"
              />
            </b-form-group>
          </div>
        </div>
        <div>
          <form>
            <div v-for="(shift, index) in shifts" :key="index">
              <fieldset class="fieldset">
                <legend>{{ shift.name }} Shift</legend>
                <div class="row">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-4">
                        <label for="" class="label-font"
                          >Shift Name <span class="text-danger">*</span></label
                        >
                        <b-form-group class="rounded">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="`Shift-${index + 1} Name`"
                          >
                            <b-form-input
                              v-model="shift.name"
                              placeholder="Enter Shift Name"
                              class="data-text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <label for="" class="label-font"
                          >Shift Start Time
                          <span class="text-danger">*</span></label
                        >
                        <b-form-group class="rounded">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="`Shift-${index + 1} Start Time`"
                          >
                            <b-form-timepicker
                              class="bg-light"
                              v-model="shift.start_time"
                              locale="en"
                              now-button
                              label-close-button="Set"
                            ></b-form-timepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <label for="" class="label-font"
                          >Shift End Time
                          <span class="text-danger">*</span></label
                        >
                        <b-form-group class="rounded">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="`Shift-${index + 1} End Time`"
                          >
                            <b-form-timepicker
                              class="bg-light"
                              v-model="shift.end_time"
                              locale="en"
                              now-button
                              label-close-button="Set"
                            ></b-form-timepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                    <div
                      v-for="(brk, idx) in shift.breaks"
                      :key="idx"
                      class="row d-flex align-items-center"
                    >
                      <div class="col-md-11">
                        <div class="row">
                          <div class="col-md-4">
                            <label for="" class="label-font"
                              >Break Name
                              <span class="text-danger">*</span></label
                            >
                            <b-form-group class="rounded">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="`Shift-${index + 1} Break-${
                                  idx + 1
                                } Name`"
                              >
                                <b-form-input
                                  v-model="brk.name"
                                  placeholder="Enter Break Name"
                                  class="data-text"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                          <div class="col-md-4">
                            <label for="" class="label-font"
                              >Break Start Time
                              <span class="text-danger">*</span></label
                            >
                            <b-form-group class="rounded">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="`Shift-${index + 1} Break-${
                                  idx + 1
                                } Start Time`"
                              >
                                <b-form-timepicker
                                  class="bg-light"
                                  v-model="brk.start_time"
                                  locale="en"
                                  now-button
                                  label-close-button="Set"
                                ></b-form-timepicker>
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                          <div class="col-md-4">
                            <label for="" class="label-font"
                              >Break End Time
                              <span class="text-danger">*</span></label
                            >
                            <b-form-group class="rounded">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="`Shift-${index + 1} Break-${
                                  idx + 1
                                } End Time`"
                              >
                                <b-form-timepicker
                                  class="bg-light"
                                  v-model="brk.end_time"
                                  locale="en"
                                  now-button
                                  label-close-button="Set"
                                ></b-form-timepicker>
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <button
                          @click.prevent="removeBreak(index, idx, brk)"
                          v-if="shift.breaks.length > 1"
                          type="button"
                          class="btn btn-danger btn-sm"
                        >
                          X
                        </button>
                        <button
                          @click.prevent="addNewBreak(index)"
                          v-if="shift.breaks.length - 1 === idx"
                          type="button"
                          class="btn btn-success btn-sm ml-1"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button
                      @click.prevent="removeShift(index, shift)"
                      v-if="shifts.length > 1"
                      type="button"
                      class="btn btn-danger btn-sm"
                    >
                      X
                    </button>
                    <button
                      @click.prevent="addNewShift"
                      v-if="shifts.length - 1 === index"
                      type="button"
                      class="btn btn-success btn-sm ml-1"
                    >
                      +
                    </button>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="row mt-3">
              <div v-if="$can('update', 'Skill')" class="col-md-12">
                <b-button @click.prevent="saveData" class="btn btn-success"
                  >Save</b-button
                >
              </div>
            </div>
          </form>
        </div>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BFormTimepicker,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { toast } from "@/utils/toast";
import moment from "moment";
import { email, required } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getLogedInUser } from "@/utils/auth";
export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormTimepicker,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      required,
      homeLink: false,
      logedInUser: JSON.parse(getLogedInUser()),
      shifts: [
        {
          name: "",
          start_time: "",
          end_time: "",
          breaks: [
            {
              name: "",
              start_time: "",
              end_time: "",
            },
          ],
        },
      ],
      clients: [],
      companies: [],
      selectedCompany: null,
      selectedCustomer: null,
    };
  },
  created() {
    if (this.logedInUser.roles[0].name === "customer") {
      this.selectedCustomer = this.logedInUser;
      this.getClientShifts();
    }
    // this.getCompanies();
  },
  methods: {
    removeShift(index, shift) {
      if (this.shifts.length > 1) {
        if (shift.id) {
          // show delete confirmation modal
          this.$bvModal
            .msgBoxConfirm("Are you sure you want to delete this shift?", {
              title: "Please Confirm",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.deleteShiftBreak(shift.id, null);
                this.shifts.splice(index, 1);
              }
            })
            .catch((err) => {
              // An error occurred
            });
        } else {
          this.shifts.splice(index, 1);
        }
      }
    },
    addNewShift() {
      this.shifts.push({
        name: "",
        start_time: "",
        end_time: "",
        status: 1,
        breaks: [
          {
            name: "",
            start_time: "",
            end_time: "",
            status: 1,
          },
        ],
      });
    },
    addNewBreak(index) {
      this.shifts[index].breaks.push({
        name: "",
        start_time: "",
        end_time: "",
      });
    },
    removeBreak(index, idx, brk) {
      if (this.shifts[index].breaks.length > 1) {
        if (brk.id) {
          // show delete confirmation modal
          this.$bvModal
            .msgBoxConfirm("Are you sure you want to delete this break?", {
              title: "Please Confirm",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.deleteShiftBreak(null, brk.id);
                this.shifts[index].breaks.splice(idx, 1);
              }
            })
            .catch((err) => {
              // An error occurred
            });
        } else {
          this.shifts[index].breaks.splice(idx, 1);
        }
      }
    },
    deleteShiftBreak(id, break_id) {
      const data = {
        id: id,
        break_id: break_id,
      };
      this.$store
        .dispatch("shift/deleteShift", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Shift Delete Successfully"
            );
            // this.getClientShifts();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    timeFormat(time) {
      return moment(time).format("hh:mm A");
    },
    async getClientShifts() {
      await this.$store
        .dispatch("shift/getClientShifts", this.selectedCustomer?.id)
        .then((response) => {
          this.shifts = response.data;
          if (this.shifts.length === 0) {
            this.shifts.push({
              name: "",
              start_time: "",
              end_time: "",
              breaks: [
                {
                  name: "",
                  start_time: "",
                  end_time: "",
                },
              ],
            });
          }
        });
    },
    async getCompanies() {
      await this.$store
        .dispatch("company/getCompaniesForDropdown")
        .then((response) => {
          this.companies = response.data;
        });
    },
    async getCustomers() {
      const id = this.selectedCompany?.id;
      let query = {
        user_id: id,
      };
      await this.$store
        .dispatch("customer/getCustomersForDropdown", query)
        .then((response) => {
          this.clients = response.data;
        });
    },

    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.shift.id) {
        this.updateShift();
      }
    },
    saveData() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.selectedCustomer) {
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              "Please Select Client"
            );
            return;
          } else {
            const data = {
              shifts: this.shifts,
              user_id: this.selectedCustomer?.id,
            };
            this.createShift(data);
          }
          // this.saveShift();
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all required fields"
          );
        }
      });
    },
    createShift(data) {
      this.$store
        .dispatch("shift/createShift", data)
        .then((response) => {
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Shift Update Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}
.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}
.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}

.fieldset {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  padding: 0.35rem 0.625rem 0.75rem !important;
}
.head-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #515b60;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>